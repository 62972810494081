.buttons-area{
    gap: 18px;
}

.buttons-area .connect-button{
    margin-top: 10px;
}

.buttons-area .load-more{
    background-color: rgb(82, 71, 71); 
    color: rgb(255, 255, 255); 
    border: 1px solid rgb(82, 71, 71); 
    padding: 6px 26px;
    letter-spacing: 0.2px; 
    border-radius: 7px; 
    font-size: 16px; 
    cursor: pointer; 
    margin-top: 10px;
}