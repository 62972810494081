.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    padding: 0em;
    min-width: 20em;
    max-width: 70%;
    color: #f00;
    background-color: #fff;
    border-radius: 1em;
    transform: translate(-50%, -50%);
    outline: transparent;
    overflow-y: scroll;
    z-index: -100;
  }
  .Overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.4);
  }