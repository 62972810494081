.successcontainer {
  background-color: #FBFBFB;
    padding: 9vh 0vh;
}


.success-container .h2_main {
  color: rgb(252, 89, 89);
  margin-bottom: 18px;
  font-size: 22px;
  font-weight: 400;
}

.success-container .h3_main {
  font-size: 22px;
  font-weight: 400;
  
}

.success-container .left-section {
  padding: 10vh 7vw;
  
  
}

.success-container .right-section {
  padding: 20px 20px;
  text-align: left;
  margin-top: 2rem;
}

.success-container .large-img {
  max-width: 100%;
  height: auto;
}

.success-container .flex-container {
  display: flex;
  align-items: center;
}

.success-container .image-container img {
  width: 100%;
  margin-top: 20px;
}

.success-container .text-container {
  flex: 2;
  margin-top: 20px;
}

.success-container .text-container>div {
  margin-bottom: 20px;
  margin-left: 26px;
}

.success-container .upper-paragraph {
  margin-top: 28px;
}

.testimonials {
  display: flex;
  justify-content: space-around;
  margin-top: 60px;
  /* width: 100%; */
  gap: 32px;
  height: auto;

}

.testimonial {
  text-align: center;
  /* margin-bottom: 150px; */
}

.testimonial img {
  
  width: 15%;
  height: 60px;
  padding: 12px;
  border-radius: 10px;
  margin-bottom: 40px;
   box-shadow: 0px -6px 40px -4px rgba(0, 0, 0, 0.080),
        0px 6px 40px -4px rgba(0, 0, 0, 0.080);
}

.red-bg {
  background-color: red;
}

.para_main {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

@media (max-width: 767px) {
  .success-container {
    background-color: #ffffff;
    padding: 20px;
  }

  .success-container .left-section .d-flex {
    justify-content: center !important;
    align-items: center !important;
    text-align: center;
  }

  .testimonials {
    flex-direction: column;
    align-items: center;
  }

  .testimonial {
    /* margin-bottom: 50px; */
  }

  .testimonial img {
    width: 54px;
    height: 54px;
    margin-bottom: 20px;
  }
 
  .success-container .btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-top: 20px;
  }

  .success-container .btn-container .btn-primary {
    width: auto;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .success-container {
    padding: 20px 50px;
  }
 
  .testimonial {
    /* margin-bottom: 150px; */
  }

}

@media (min-width: 992px) {
  .testimonial {
    /* margin-bottom: 200px; */
  }
}