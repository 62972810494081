.mission-container {
    background-color: transparent;
    padding: 20px 50px;
  }


.mission-container .para_main {
  margin-top: 22px;
}

.mission-container .h3_main {
  font-size: 22px;
  font-weight: 400;
}

.mission-container .left-section {
  padding: 15vh 0;
}

.mission-container .right-section {
  padding: 20px 20px;
  text-align: left;
  margin-top: 2rem;
}

.mission-container .large-img {
  max-width: 100%;
  height: auto;
}

.mission-container .flex-container {
  display: flex;
  align-items: left;
  max-width: 85%;
}

.mission-container .image-container img {
  width: 100%;
  margin-top: 20px;
}


.mission-container .text-container {
  margin-bottom: 20px;
}

.mission-container .upper-paragraph {
  margin-top: 28px;
}

.mission-container .connect-button {
  margin-top: 30px;
  background-color: rgba(255, 0, 0);
  color: rgb(255, 255, 255);
  border: 1px solid red;
  padding: 8px 22px;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .mission-container .flex-container {
    display: flex;
    align-items: left;
    max-width: 100%;
  }

  .mission-container .left-section {
    padding: 4vh 0;
    text-align: center;
  }
}