.newsupdates-hero {
    position: relative;
    height: 86vh;
    background: url('../../../public/images/Group2028.jpg') no-repeat center center/cover;
  }
  
  .NewsAndUpdates-content-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.5); */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .NewsAndUpdates-content {
    color: white;
    max-width: 850px;
    padding: 20px;
    margin-top: 50px;
  }
  
  .NewsAndUpdates-content h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    font-weight: 500;
  }
  
  .NewsAndUpdates-content p {
    font-size: 1rem;
    margin-bottom: 30px;
    line-height: 2;
  }
  