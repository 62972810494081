.main-cardgrid .text-muted {
    color: rgb(252, 89, 89) !important;
}

.main-cardgrid .card-title {
    color: #1F2F54;
    font-size: 24px;
    line-height: 1.5;
}

.main-cardgrid .href {
    color: #717C93;
    margin-top: 5px;
}

.main-cardgrid .card-body {
    padding: 22px 0px;
}

.main-cardgrid .arrow-img {
    margin-left: 10px;
}