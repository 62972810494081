
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 26px;
    max-width: 1200px;
    padding: 10vh 0vh;
    justify-content: center;
  }
  
  .cards-container .card {
    width: calc(25% - 20px);
    padding: 50px 32px 35px 32px;
    border-radius: 12px;
    box-shadow: 0px -6px 40px -4px rgba(0, 0, 0, 0.080), 
    0px 6px 40px -4px rgba(0,0,0,0.080);  
    text-align: center;
    border: none;
    background-color: #fff;
    
  }
  
  .cards-container .icon {
    width: 50px;
    height: 50px;
    margin: 0 auto 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .cards-container .icon img {
    width: 80px;
  }
  
  .cards-container .title {
    font-size: 1.3em;
    color: #1F2F54;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 12px;
  }
  
  .cards-container .subtitle {
    font-size: 15px;
    font-weight: 400;
  font-family: "Inter", sans-serif;
    color: #717C93;
    margin-bottom: 20px;
  }
  
  .cards-container .btnCard {
    background-color: #007bff;
    border: none;
    border-radius: 7px;
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    padding: 8px;
    width: 100%;
    margin-top: 6px;
    box-sizing: border-box;
  }
  
  .cards-container .btnCard:hover {
    opacity: 0.8;
  }

  .cards-container .para_main{
    margin-bottom: 12px;
  }
  
  
  @media (max-width: 1200px) {
    .cards-container .card {
      width: calc(33.333% - 20px);
    }
  }

  @media (max-width: 1024px) {
    .cards-container .card {
      width: calc(50% - 20px);
    }
  }
  
  @media (max-width: 768px) {
    .cards-container .card {
        width: 100%;
    }
  }
  
 
  