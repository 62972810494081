.wrapper-div {}

.courses-card .card {
  padding: 4vh 6vh;
  box-shadow: 0px -6px 40px -4px rgba(0, 0, 0, 0.080),
    0px 6px 40px -4px rgba(0, 0, 0, 0.080);
  border: none;
  border-radius: 12px;
}

.courses-card .h1_main {
  font-size: 35px;
  margin-bottom: 12px;
}

.courses-card .para_main {
  margin-bottom: 14px;
}

.courses-card .para_course_main {
  margin: 0;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: #717C93;
  font-size: 15px;
  margin-left: 12px;
  line-height: 2;
}

.courses-card .connect-button {
  width: 100%;
  margin-top: 12px;
}

.courses-card .card-body {
  padding: 3vh 3vh;
}

.courses-card .courses-img {
  margin-left: 15px;
}

.courses-card .course-content {
  margin-top: 38px;
}

.courses-card .card-img {
  width: 1005;
}


.courses-card .price_text{
  font-size: 17px;
  font-weight: 600;
  color: #1F2F54;
  margin: 0px;
  font-family: "Inter", sans-serif;
}

.courses-card .cut_red
{
  text-decoration: line-through;
  color: red!important;
}

@media (max-width: 992px) {
  .courses-card .card {
    padding: 3vh 4vh;
  }

  .courses-card .h1_main {
    font-size: 28px;
  }

  .courses-card .para_course_main {
    font-size: 14px;
  }
}

@media (max-width: 1400px) {
  .courses-card .card {
    padding: 4vh 4vh;
  }

  .courses-card .card-body {
    padding: 2vh 0.5vh;
  }
}