html,
body {
  height: 100%;
  margin: 0;
  font-family: "Inter", sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
  width: 8px;
 color: #ffffffbd;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(238, 238, 238); 
  background-color: #eaeaea;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #454545; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(15, 15, 15); 
}

.main {
  width: 100%;
  height: 100%;
  padding: 40px 0px 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 30px;
}
.head {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  padding-right: 30px;
  gap: 20px;
}
/* .heading_capital {
  font-family: "Inter", sans-serif;
  font-size: 40px;
  color: #1d1d1d;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
}

.heading_main {
  font-family: "Inter", sans-serif;
  font-size: 32px;
  color: #1d1d1d;
  font-weight: 800;
  text-transform: uppercase;
  margin: 0;
} */

/* .heading_capital span {
  color: #cb1212;
} */

.h1_main {
  font-size: 20px;
  font-weight: 600;
  /* text-transform: uppercase; */
  margin: 0px;
  font-family: "Inter", sans-serif;
  color: #1F2F54;
}
.h2_main {
  font-size: 18px;
  font-weight: 500;
  color: #EE202D;
  margin: 0px;
  font-family: "Inter", sans-serif;
  margin-bottom: 16px;
}

.h2_main span {
  color: #cb1212;
  font-family: "Inter", sans-serif;
}

.cart-red-icon
{
  background-color: #EE202D;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  transform: translate(-10px,-10px)
}

.listings_view_wrapper {
  padding: 36px 45px;
}

.listings_view_wrapper .h1_main {
  margin-bottom: 2rem;
}

.h3_main {
  font-size: 22px;
  font-weight: 400;
  margin: 0px;
  font-family: "Inter", sans-serif;
  color: #1F2F54;
}

.h3_main span {
  color: #cb1212;
  font-family: "Inter", sans-serif;
}
.h4_main {
  font-size: 14px;
  margin: 0px;
  font-family: "Inter", sans-serif;
}
.h6_main {
  font-weight: 400;
  font-size: 14px;
  margin: 0px;
  color: #717171;
  font-family: "Inter", sans-serif;
}
.highlight_document_code_color {
  color: #2ec0be;
} 
.para_main{
  margin: 0;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: #717C93;
  font-size: 15px;
  margin-top: 8px;
  line-height: 2;
}
.button_main{ 
  border: none;
  font-weight: 600;
  color: #fff;
  font-size: 18px; 
  background-color: #2ec0be;
  border: 1px solid #2ec0be;
  padding: 5px 35px 5px 35px;
  border-radius: 4px;
}
.button_secondary {
  border: none;
  font-weight: 600;
  color: #000000;
  font-size: 18px;
  background-color: transparent;
  border: 1px solid #000000;
  padding: 5px 35px 5px 35px;
  border-radius: 4px;
}
.filterButton {
  cursor: pointer;
  user-select: none;
  border: 1px solid rgb(255, 255, 255);
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  font-weight: 600;
  padding: 6px 20px 6px 20px;
  border-radius: 5px;
  font-size: 14px;
  color: rgb(255, 255, 255);
  gap: 10px;
  /* background-image: linear-gradient(to right, #1fbbc2, #67d1ab); */
  background-image: linear-gradient(to right, #191c1c, #626967);
}
.datepicker-wrapper label {
  color: #191c1c;
  font-weight: 500;
  font-size: 16px;
}
.datepicker-wrapper .react-datepicker-wrapper {
  width: 100%;
}
.uploader-component-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-right: 30px;
  gap: 20px;
}
.uploader-component-wrapper .upload {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border: 2px dashed #1fbbc2;
  padding: 30px;
  gap: 25px;
  border-radius: 12px;
  align-self: flex-start;
}

.uploader-component-wrapper .upload h3 {
  font-weight: 600;
  color: black;
  padding-bottom: 7px;
}
.uploader-component-wrapper .upload input {
  color: #1fbbc2;
  background-color: #fbfdfe;
  font-size: 15px;
  padding: 10px 18px 10px 18px;
  border-radius: 8px;
  cursor: pointer;
}
.uploader-component-wrapper .upload input:hover {
  background-color: #f4fbff;
  cursor: pointer;
}
.uploader-component-wrapper label {
  cursor: pointer;
}

.connect-button{
  /* margin-top: 30px; */
  background-color: rgba(255, 0, 0); 
  color: rgb(255, 255, 255); 
  border: 1px solid red; 
  padding: 6px 26px;
  letter-spacing: 0.2px; 
  border-radius: 7px; 
  font-size: 16px; 
  cursor: pointer; 
  margin-top: 40px;
}

.common_admin_wrapper {
  background-color: transparent;
  background-size: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup_wrapper { 
  background: #fff;
  height: 35vh;
  border-radius: 10px;
  padding: 0 25px;
}

/* admin portal input styling */
.HeaderSliderWraper-container select,
.HeaderSliderWraper-container input:not([type="checkbox"])
{
  border: none!important;
  border-radius: 0!important;
  border-bottom: 2px solid rgb(73, 54, 54)!important;
  color: black!important;
  font-size: 17px!important;
  transition: .3s;
}

.HeaderSliderWraper-container input:not([type="checkbox"]):focus,
.HeaderSliderWraper-container select:focus
{
  border-bottom: 2px solid rgb(117, 140, 217)!important;
}

.HeaderSliderWraper-container .connect-button
{
  font-size: 20px!important;
  font-weight: 600;
}
.HeaderSliderWraper-container select
{
  /* padding: 15px 5px!important; */
}
.HeaderSliderWraper-container .react-datepicker__input-container input
{
  padding: 25px 5px!important;
}
.HeaderSliderWraper-container label
{
  margin-top: 15px!important;
}

.u_p_wraper
{
  background: #fff;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #dfe0eb;
    box-sizing: border-box;
    height: 40px;
    margin: 0 10px;
    position: relative;
    width:40px;
}

.chart-container .page-size
{
  display: flex;
  align-items: center;
  margin: 10px 0; 
  border: 1px solid #d7d7d7!important;
  border-radius: 5px!important;
  padding: 10px!important;
  width: max-content;
}

.chart-container .page-size label {
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
  margin-top: 0px!important;
}

.chart-container .page-size select {
  padding: 0px!important;
  font-size: 16px;
  outline: none;
  cursor: pointer;
  border: none!important;
  transition: border-color 0.3s ease;
}

.chart-container .page-size select option {
  padding: 10px;
  font-size: 16px;
  background-color: #fff;
  color: #333;
}

.chart-container .page-size select:focus {
  border-color: #d7d7d7;
}

.dashboardDisplay-container
{
  height: 150px!important;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 992px) {
  .h2_main {
    font-size: 16px;
  }
  .h4_main {
    font-size: 12px;
  }
  .connect-button{
    margin-top: 22px;
  }
}
