.ProfilePopup-container{
    
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .ProfilePopup-container .row {
    margin: 0;
  }
  
  .ProfilePopup-container .img-modal {
    padding: 10px;
  }
  
  .ProfilePopup-container .icon-cross {
    cursor: pointer;
  }
  
  .test-marks-popup-box {
    padding: 5vh 3vh;
    overflow-y: auto;
   
  }
  
  .ProfilePopup-container .h1_main {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  
  .ProfilePopup-container .genral-btn
  {
      background-color: #EE202D;
      color: white;
      padding: 8px 20px;
      border: none;
      border-radius: 10px;
      font-weight: 500;
      width: 100%;
  }
  
  .ProfilePopup-container .profile-form-field {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    color: rgb(204, 204, 204);
  }
  .ProfilePopup-container .profile-form-field:focus {
    outline: none;
  }
  
  .ProfilePopup-container .input-label
{
    color: grey;
}

.ProfilePopup-container img,p,h1,h2,h3,h4,h5,h6{
    user-select: none!important;
}

@media (max-width: 768px) {
  .ProfilePopup-container {
    width: 100%; 
   
  }
  .test-marks-popup-box {
    padding: 9vh 1vh;
  }
  .ProfilePopup-container .icon-cross {
    height: 25px;
  }

}

@media (max-width: 576px) {
  .test-marks-popup-box {
    padding: 9vh 1vh;
  }
  
}