  .curriculam-main {
    /* padding: 0vh 0vh; */
    font-family: "Inter", sans-serif;
  }
  .row-class{
    width: 100%;
  }

  .curriculam-main .main-heading {
    color: #1F2F54;
  }

  .curriculam-main .curriculam-start {
    padding: 16vh 5vh;
  }

  .curriculam-main .card {
    border: none;
    border-radius: 20px;
    overflow: hidden;
    padding: 5vh 5vh;
    box-shadow: 0px -6px 40px -4px rgba(0, 0, 0, 0.080),
      0px 6px 40px -4px rgba(0, 0, 0, 0.080);
  }

  .curriculam-main .card-img-top {
    width: 100%;
    border-radius: 12px;
    margin-bottom: 8px;
  }

  .curriculam-main .card-title {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 14px;
    color: #1F2F54;
  }

  .curriculam-main .red-cutted
  {
    text-decoration: line-through;
    color: red !important;
  }

  .curriculam-main .lecture-class {
    color: #1f2f54ee;
  }

  .curriculam-main .list-group-item {
    border: none;
    padding: 2rem 0rem;
    border-bottom: 1px solid lightgray;
    align-items: center;
    background-color: transparent;
  }

  .curriculam-main .list-group-item h5 {
    margin-bottom: 0.5rem;
  }

  .curriculam-main .list-group-item p {
    margin-bottom: 0;
    color: #1f2f54dc;
  }

  .curriculam-main .fa-lock {
    color: #1F2F54;
  }

  .curriculam-main .connect-button {
    width: 100%;
    padding: 16px 0px;
  }

  .curriculam-main .right {
    margin-left: auto;
  }

  .curriculam-main .right-text {
    margin-left: auto;
    color: rgb(133, 132, 132);
    font-size: 22px;
    align-items: center;
    font-weight: 600;
  }

  .curriculam-main .box-text {
    margin-left: 25px;
    display: flex;
    align-items: center;
  }

  .span-text {
    margin-left: auto;
  }

  .Box-main {
    margin-bottom: 12px;
    margin-top: 32px;

  }

  .curriculam-main .divider {
    border-bottom: 0.1px solid rgba(173, 172, 172, 0.103);
    margin-top: 18px;
  }

  .curriculam-main .img-class {
    margin-left: 14px;
  }

  @media (max-width: 992px) {

    .curriculam-start,
    .card {
      padding: 4vh 1vh;
    }
  }

  @media (max-width: 768px) {
    .curriculam-start {
      padding: 4vh 0.5vh;
    }   

    .card {
      padding: 3vh 0.5vh;
    }
  }

  @media (max-width: 576px) {
    .curriculam-main .card {
      padding: 0vh 0vh;
    }
  }