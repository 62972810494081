.signup-popup-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10vh 10vh;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
}

.login-popup-section{
  display: flex;
    align-items: center;
    justify-content: center;
    padding: 10vh 10vh;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
}

.login-popup-section {
  background-color: white;
}

.signup-popup-section {
  background-color: #e91829;
  color: white;
}

.login-popup-box, .signup-popup-box {
  
  width: 100%;
  padding: 20px;
  text-align: center;
  
}

.login-popup-box h2, .signup-popup-box h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

/* .login-box h2 {
  color: #717C93;
} */

.signup-popup-box h2 {
  color: white;
}

.social-login {
  margin: 20px 0;
}

.social-login .btn {
  margin: 0 5px;
  width: 45px;
  height: 45px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.social-login .btn i {
  font-size: 1.5rem;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  height: 45px;
  border-radius: 5px;
  font-size: 12px;
  color: #717C93;
}

.forgot-password {
  display: block;
  margin-bottom: 15px;
  color: #A7A7A7;
  font-size: 13px;
}

.login-section .connect-button{
  font-size: 13px;
}



.btn-block {
  width: 100%;
  background-color: #e91829;
}

.signup-popup-box p {
  margin: 20px 0;
}

.btn-outline-light {
  border-color: white;
  color: white;
  padding: 6px 45px;
  font-size: 12px;
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: #A7A7A7;
  font-size: 12px;
}

.create-accout-section .img-modal{
  top: 0;
  right: 0;
  position: absolute;
}

.login-popup-section .icon-cross{
  height: 28px;
  
}



@media (max-width: 768px) {
  .login-popup-section {
    padding: 4vh 3vw;
      border-radius: 0px;
      height: 100vh;
  }
  
  .login-popup-box {
    padding: 1vh 1vh; 
  }
  
  .btn-outline-light {
    padding: 6px 30px; 
  }
  .login-popup-section {
     
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    padding: 8vh 2vh;
  
  }
}

@media (max-width: 576px) {
  .login-popup-section {
    padding: 0vh 3vw; 
      border-radius: 0px;
  }

  .login-popup-box {
      padding: 1vh 1vh; 
    }
  
  .login-popup-box h2
  {
    font-size: 1.5rem; 
  }

  
  .btn-outline-light {
    padding: 6px 20px; 

  }

  .login-popup-box .forgot-password{
    color: #717C93;
  }

  .login-popup-section .icon-cross{
    margin-top: -191px;
  }
}
