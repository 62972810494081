.drive-btn {
    background-color: rgba(255, 0, 0);
    color: rgb(255, 255, 255);
    border: 1px solid red;
    padding: 10px 26px; /* Adjusted padding for better button appearance */
    letter-spacing: 0.2px;
    border-radius: 7px;
    font-size: 16px;
    cursor: pointer;
}



.curriculam-main {
    padding: 20px 1px; /* Default padding for large screens */
    font-family: "Inter", sans-serif;
}

.curriculam-main .card {
    border: none;
    border-radius: 20px;
    overflow: hidden;
    padding: 15px 15px;
    box-shadow: 0px -6px 40px -4px rgba(0, 0, 0, 0.080),
                0px 6px 40px -4px rgba(0, 0, 0, 0.080);
}

.curriculam-main .card-img-top {
    width: 100%;
    border-radius: 12px;
    margin-bottom: 8px;
}

.curriculam-main .card-title {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 14px;
    color: #1F2F54;
}

.curriculam-main .list-group-item {
    border: none;
    padding: 2rem 0rem;
    border-bottom: 1px solid lightgray;
    align-items: center;
    background-color: transparent;
}

.curriculam-main .list-group-item h5 {
    margin-bottom: 0.5rem;
}

.curriculam-main .lecture-class {
    color: #1f2f54ee;
}

.curriculam-main .connect-button {
    width: 100%;
    padding: 16px 0px;
}

.Box-main {
    margin-bottom: 12px;
    margin-top: 32px;
}

.curriculam-main .divider {
    border-bottom: 0.1px solid rgba(173, 172, 172, 0.103);
    margin-top: 18px;
}

@media (max-width: 992px) {
    .curriculam-main .card,
    .curriculam-start {
        padding: 4vh 1vh; /* Adjusted padding for medium screens */
    }
}

@media (max-width: 768px) {
    .curriculam-main .card,
    .curriculam-start {
        padding: 4vh 0.5vh; /* Adjusted padding for small screens */
    }

    .drive-btn {
        padding: 10px 20px; /* Adjusted button padding for small screens */
    }
}

@media (max-width: 576px) {
    .curriculam-main .card {
        padding: 6vh 2vh; /* Adjusted padding for extra small screens */
    }

    .curriculam-main .list-group-item {
        flex-direction: column;
    }

    .curriculam-main .list-group-item .text-right {
        margin-top: 10px;
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .curriculam-main .list-group-item .drive-btn {
        margin-top: 10px;
    }
    
    .drive-btn {
        padding: 10px 16px; /* Further adjusted button padding for extra small screens */
    }
    
    .curriculam-main .duration {
        font-size: 14px; /* Adjusted duration font size for extra small screens */
    }
}