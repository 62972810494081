.AdminHeader-container {
    border-bottom: 2px solid #F2F2F2;
    padding: 10px 20px; 
}

.AdminHeader-container .menu-btn-container {
    cursor: pointer;
}

.AdminHeader-container .menu-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.AdminHeader-container .bar {
    display: none; /* Hide the bar element on mobile */
}

.AdminHeader-container .title {
    color: black;
    font-weight: bold;
    font-size: 18px;
    text-transform: capitalize;
    margin-left: 10px; /* Add margin for spacing */
}

.AdminHeader-container .profile-section {
    margin-left: auto; /* Push profile section to the right on mobile */
}

.AdminHeader-container .profile-section .menu-icon {
    width: 25px;
    height: 25px; /* Reduce size of profile and cart icons */
}

.AdminHeader-container .navigation-dropdown {
    position: relative;
}

.AdminHeader-container .dropdown-trigger {
    cursor: pointer;
    color: black; /* Adjust color for visibility */
    display: flex;
    align-items: center;
}

.AdminHeader-container .dropdown-trigger img {
    width: 20px;
    height: 20px; 
    margin-left: 5px; 
}

.AdminHeader-container .dropdown-content {
    position: absolute;
    top: 130%; 
    left: -70px;
    background-color: white;
    min-width: 150px; 
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 10px;
    padding: 10px;
    display: none; 
}

.AdminHeader-container .dropdown-content.open {
    display: block; 
}

.AdminHeader-container .dropdown-content .nav-links {
    color: black;
    list-style: none;
    text-decoration: none;
    cursor: pointer;
    padding: 8px 0;
    text-align: center; /* Center align text */
}

.AdminHeader-container .dropdown-content .nav-links:hover {
    color: #ee202d;
}

.AdminHeader-container .rotate-icon {
    transform: rotate(180deg); /* Set initial rotated state */
    transition: transform 0.3s ease;
}

.AdminHeader-container .rotate-back {
    transform: rotate(0deg); /* Set initial non-rotated state */
    transition: transform 0.3s ease;
}

.AdminHeader-container .user-icon{
    height: 60px!important;
    width: 60px!important;
    border-radius: 50%;
  }

/* Media query for screens smaller than 992px (lg breakpoint) */
@media (max-width: 991.98px) {
    .AdminHeader-container {
       padding: 0vh 1vh;
    }

    .AdminHeader-container .menu-btn-container {
        margin-right: 10px; 
    }

    .AdminHeader-container .title {
        font-size: 16px; 
    }

    .AdminHeader-container .profile-section {
        gap: 10px; 
    }

    .AdminHeader-container .dropdown-content {
        min-width: 120px; 
        padding: 8px; 
    }
    .AdminHeader-container .menu-icon {
        width: 20px;
        height: 20px;
       
    }
    .AdminHeader-container .title {
        color: black;
        font-weight: bold;
        font-size: 18px;
        text-transform: capitalize;
        margin-left: 0px; 
    }
}
