.CartPopup-container {
    height: 100%;
    overflow-y: auto;
    /* max-height: 700px; */
}


.CartPopup-container .main-container {
    border-radius: 15px;
    background-color: white;
    padding: 0;
}

.CartPopup-container .card-box {
    border-radius: 10px;
    border: 1px solid rgba(128, 128, 128, 0.464);
}




.CartPopup-container .delete-btn {
    background-color: gray;
    color: white;
    text-decoration: underline;
    padding: 8px 20px;
    border: none;
    border-radius: 10px;
}


.CartPopup-container .delete-btn-cart {
    background-color: rgba(128, 128, 128, 0);
    color: #EE202D;
    text-decoration: underline;
    padding: 8px 20px;
    border: none;
    border-radius: 10px;
    font-weight: 500;
}

.CartPopup-container .genral-btn {
    background-color: #EE202D;
    color: white;
    padding: 8px 20px;
    border: none;
    border-radius: 10px;
    font-weight: 500;
    width: 100%;
}

.CartPopup-container .delete-icon {
    margin-right: 10px;
}

.CartPopup-container .cross-icon {
    cursor: pointer;
}

.CartPopup-container .h1_main {
    font-size: 22px;
    font-weight: bold;
    color: #4D5976;
}


.CartPopup-container .grey-header {
    background-color: #F2F2F2;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}


.CartPopup-container .body-paragraph {
    color: grey;
}

.CartPopup-container .red-text {
    color: #EE202D;
    font-weight: 500;
    font-size: 16px;
}

.CartPopup-container .cart-slider {
    height: 500px;
    max-height: 50%;
    overflow-y: auto;
}

@media(max-width:993px) {
    .CartPopup-container .card-box {
        border: none;
    }
}

@media(max-height:720px) {
    .CartPopup-container .cart-slider {
        height: 60vh;
        overflow-y: auto;
    }
}