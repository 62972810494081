.sponsor-container
{
    
  margin-left: auto;
  margin-right: auto;
  
}

.sponsor-container .companies-logo-box .swiper-slide 
{
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
}
.sponsor-container .companies-logo-box .swiper-wrapper {
    width: 100%;
    max-width: 100%;
    max-height: 100vh;
   
    min-height: 0;
    min-width: 0;}


 

@media(max-width:992px)
{
    .sponsor-container .companies-logo-box .swiper-slide 
    {
      
    }
}

