/* Common Dropdown CSS */
.dropdown-wrapper {
	position: relative;
	display: inline-block;
	width: 100%;
	user-select: none;
}

.dropdown-wrapper .dropdown-select {
	
	font-size: 16px;  
	height: 45px;
	border-radius: 5px;
	color: #717C93;
	border: 1px solid #ccc;
	width: 100%;
	box-sizing: border-box;
	outline: none;  
	padding: 0;
	padding-left: 10px;
	margin: 7px 0;
}

.dropdown-wrapper .dropdown-select:focus {
	outline: none;
} 

/* Common Checkbox Stylings */
.checkbox-wrapper {
	display: flex; 
    align-items: baseline;  
	justify-content: center;
    gap: 2px;
}

.checkbox-wrapper input[type='checkbox'] {
	margin-right: 3px; 
    width: 20px;
    height: 15px;
}

.checkbox-wrapper input[type='checkbox']:hover {
	cursor: pointer;
}

/* Common Input Component Stylings */
.input-container { 
	width: 100%;
}
 
.input-container input,
.input-container select
 { 
	font-size: 16px;  
	height: 45px;
	border-radius: 5px;
	color: #717C93;
	border: 1px solid #ccc;
	width: 100%;
	box-sizing: border-box;
	outline: none;  
	padding: 0;
	padding-left: 10px;
	margin: 7px 0;
	transition: 0.3 all ease-in-out;
}


.input-container input:focus
{
	border-color: rgb(45, 116, 224); 
	transition: 0.3 all ease-in-out;
}

/* Common Label Stylings */
.dropdown-wrapper label, 
.checkbox-wrapper label,
.password_visibility_wrapper label,
.image-upload-container .main-label,
.input-container label {
	display: block;
	color: #191c1c;
	font-weight: 500; 
	font-size: 16px;
	user-select: none;
	text-align: start;
}

/* Common Navigation Header Stylings */
.navigation_header_wrapper {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 30px;
}

.navigation_header_wrapper .action_btn_wrapper {
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.password_visibility_wrapper {
    position: relative;
}
.password_eye_icon_wrapper {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-6%);
  cursor: pointer;
}

.password-input-eye-icon
{
	width: 25px;
	height: 25px;
}


.multi-select-main
{
	position: relative;
}

.multi-select-main .multi-select-inp
{
	cursor: pointer;
	border-bottom: 2px solid black;
	
}
  
.multi-select-main .multi-select-inp:focus
{
	outline: none;
}
  
.multi-select-main .dropdown-menu {
	display: block;
	position: absolute;
	background-color: #f9f9f9;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 1;
	max-height: 200px;
	top: 100%;
	overflow-y: auto;
	width: 100%;
	overflow-x: auto;
}

.multi-select-main .dropdown-menu::-webkit-scrollbar {
    height: 8px;
    width: 6px; 
}

  
  .multi-select-main .dropdown-menu .dropdown-item {
	padding: 10px;
	cursor: pointer;
	text-align: start;
	display: flex;
	align-items: center;
	justify-content: start;
  }
  
 .multi-select-main .dropdown-menu .dropdown-item input {
	margin-right: 10px;
  }
  
.multi-select-main .dropdown-menu .dropdown-item:hover {
	background-color: #f1f1f1;
  }
  
  .multi-select-main .multi-select button{
	background-color: rgba(255, 0, 0); 
	color: rgb(255, 255, 255); 
	border: 1px solid red; 
	padding: 6px 26px;
	letter-spacing: 0.2px; 
	border-radius: 7px; 
	font-size: 16px; 
	cursor: pointer; 
	height: fit-content;
	
  }

  .input-container input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
	  -webkit-appearance: none;
	  margin: 0;
  }
  
  .input-container input[type=number] {
	  -moz-appearance: textfield;
  }


  .input-container .other-text-input
  {

  }
  
.input-container .other-text-input:focus
{
	outline: none!important;
}

.multi-select-main .dropdown-search-input
{
	position: sticky;
	top: 0;
	z-index: 200;
}
.multi-select-main .dropdown-search-input:focus
{
	outline: none;
}
  