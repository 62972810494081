.PaymentCheckoutForm-contaienr
{

}
.PaymentCheckoutForm-contaienr .profile-form-field {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    color: rgb(92, 92, 92);
  }
  
.PaymentCheckoutForm-contaienr .profile-form-field:focus {
    outline: none;
  }
  
.PaymentCheckoutForm-contaienr .genral-btn
{
    background-color: #EE202D;
    color: white;
    padding: 8px 20px;
    border: none;
    border-radius: 10px;
    font-weight: 500;
    width: 30%;
}
.PaymentCheckoutForm-contaienr .total-text
{
  font-weight: 700;
  font-size: 20px;
}
.PaymentCheckoutForm-contaienr .color-red
{
  color: #EE202D;
}

.PaymentCheckoutForm-contaienr .copy-btn
{
  border-radius: 50%;
  background-color: rgb(224, 224, 224);
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  cursor: pointer;
}

.PaymentCheckoutForm-contaienr .bank-details-text
{
  font-weight: 700;
  font-size: 22px;
}

@media(max-width:993px)
{
  .PaymentCheckoutForm-contaienr .bank-details-text
  {
    font-weight: 700;
    font-size: 18px;
  }
  .PaymentCheckoutForm-contaienr .total-text
  {
    font-weight: 700;
    font-size: 18px;
  }
}