.news-events-container {
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between; */
    padding: 20px;
    background-color: transparent;
}

.news-events-container .h1_main {
    font-size: 22px;
    margin-bottom: 18px;
}

.news-events-container .box-section {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px -6px 40px -4px rgba(0, 0, 0, 0.080),
        0px 6px 40px -4px rgba(0, 0, 0, 0.080);
    
}
.news-events-container .section {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px -6px 40px -4px rgba(0, 0, 0, 0.080),
        0px 6px 40px -4px rgba(0, 0, 0, 0.080);
    margin-bottom: 20px;
    padding: 20px;
    width: 100%;
}

.news-events-container .section h3 {
    font-size: 18px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.news-events-container .news-event-item,
.course-item,
.marks-attendance-item,
.online-class-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

}

.news-events-container .news-event-item {
    padding: 0.7vh 0vh;
}

.news-events-container .marks-attendance-info {
    margin-left: 40px;
}

.news-events-container .news-event-item .date,
.marks-attendance-item .topic-date,
.online-class-item .class-date-time {
    margin-right: 20px;
    font-size: 14px;
}

.news-events-container .news-event-item .date div,
.marks-attendance-item .marks,
.marks-attendance-item .attendance,
.online-class-item {
    font-size: 16px;
    font-weight: bold;
}

.news-events-container .class-date-time {
    font-size: 15px;
    color: #afafaf;
    margin-top: 12px;
    font-weight: lighter;

}

.news-events-container .news-event-item .content p,
.course-info p,
.marks-attendance-item .subject-name,
.online-class-item .subject-name {
    margin: 0;
    font-weight: 700;
}


.news-events-container .news-event-item .content span,
.marks-attendance-item .topic-date a,
.see-more {
    color: #63acfa;
    text-decoration: none;
    font-size: 12px;
}

.news-events-container .course-item img,
.online-class-item img {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    margin-right: 20px;
}

.news-events-container .course-info,
.class-info {
    flex-grow: 1; 
}

.news-events-container .course-info .teacher-name,
.marks-attendance-item .marks,
.marks-attendance-item .attendance,
.time-date {
    color: #ff0000;
}

.news-events-container .zoom-icon {
    width: 40px;
    height: 40px;
}

.news-events-container .course-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.news-events-container .course-item img {
    width: 100px;
    height: auto;
    margin-right: 10px;
}

.news-events-container .course-info {
    flex: 1;
}

.news-events-container .teacher-name {
    font-weight: bold;
}

.news-events-container .course-name {
    margin-top: 5px;
}

.news-events-container .see-more {
    float: right;
    font-size: 14px;
    color: #007bff;
    cursor: pointer;
}

.news-events-container .news-event-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.news-events-container .date {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    padding-right: 10px;
    border-right: 3px solid rgb(50, 50, 255);
}

.news-events-container .date-text {
    color: #007bff;
}

.news-events-container .zoom-icon {
    width: 135px;
    height: 80px;
    border-radius: 12px;
}

.news-events-container .enrolledCourseSection
{
    height: 340px;
    overflow-y: auto;
}


@media (max-width: 992px) {
    .news-events-container .section {
        width: 100%;
    }
    .news-events-container .zoom-icon {
        width: 80px;
        height: 50px;
    }
}

@media (max-width: 768px) {
    .news-events-container {
        padding: 10px;
    }

    .news-events-container .section {
        padding: 15px;
    }

    .news-events-container .news-event-item,
    .news-events-container .course-item,
    .news-events-container .marks-attendance-item{
        align-items: flex-start;
    }

    .news-events-container .online-class-item {
        align-items: center;
    }

    .news-events-container .course-item img {
        width: 80px;
        height: auto;
        margin-bottom: 5px;
    }

    .news-events-container .marks-attendance-info {
        margin-left: 0px;
        margin-top: 12px;
    }

    .news-events-container .marks-attendance-item .topic-date,
    .online-class-item .class-date-time {
        margin-right: 0px;
    }

    .news-events-container .course-info p,
    .marks-attendance-item .subject-name,
    .online-class-item .subject-name {
        margin-top: 12px;
    }

    .news-events-container .h1_main {
        font-size: 18px;
        font-weight: 700;
    }

    .news-events-container .course-item img,
    .online-class-item img {
        margin-right: 0px;
    }
}

@media (max-width: 576px) {

    .news-events-container .course-item,
    .news-events-container .marks-attendance-item,
    .news-events-container .online-class-item {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .news-events-container .section {
        width: 100%;
    }

    .news-events-container .course-item img {
        width: 60px;
        height: auto;
    }
}