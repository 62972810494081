.latest-container {
    position: relative;
    transform: translateY(-80px); /* Adjust this value to move the container up or down */
    z-index: 2;
    background: white;
    box-shadow: 0px -6px 40px -4px rgba(0, 0, 0, 0.080),
        0px 6px 40px -4px rgba(0, 0, 0, 0.080);
    padding: 6vh 8vh;
    border-radius: 8px;
    width: 100%; 
    
  }

  .latest-container .h1_main{
    font-size: 2rem;
  }
  
  h2 {
    font-weight: bold;
  }

  .content-div{
    margin-top: 42px;
  }
  
  .btn-link {
    /* text-decoration: none; */
    color: #717C93;
    padding-left: 0px;
    margin-top: 12px;
  }

  .img-responsive{
    /* max-width: 100%; */
    width: 90%;
  
  }

  .arrow-img{
    margin-left: 7px;
  }

  @media (max-width: 768px) {
    .latest-container{
      padding: 6vh 4vh;
      transform: translateY(-30px); 
      text-align: center;
    }
    .latest-container .h2_main{
      margin-top: 10px;
    }
    .latest-container .h1_main{
      font-size: 1.4rem;
    }
    .latest-container .content-div{
      margin-top: 12px;
    }
    .latest-container .img-responsive{
      width: 100%;
    }
  }
  