

.welcome-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10vh 10vh;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

 
  
  .create-accout-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10vh 10vh;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    
    
  }
  
  .create-accout-section {
    background-color: white;
  }
  
  .welcome-section {
    background-color: #e91829;
    color: white;
  }
  
  .login-box, .signup-box {
    
    width: 100%;
    padding: 20px;
    text-align: center;
    
  }
  
  .login-box h2, .signup-box h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  /* .login-box h2 {
    color: #717C93;
  } */
  
  .signup-box h2 {
    color: white;
  }
  
  .social-login {
    margin: 20px 0;
  }
  
  .social-login .btn {
    margin: 0 5px;
    width: 45px;
    height: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  .social-login .btn i {
    font-size: 1.5rem;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-control {
    height: 45px;
    border-radius: 5px;
    font-size: 12px;
    color: #717C93;
  }
  
  .forgot-password {
    display: block;
    margin-bottom: 15px;
    color: #A7A7A7;
  }

  .create-accout-section .connect-button{
    font-size: 13px;
  }
  
  .btn-block {
    width: 100%;
    background-color: #e91829;
  }
  
  .signup-box p {
    margin: 20px 0;
  }
  
  .btn-outline-light {
    border-color: rgba(206, 204, 204, 0.795);
    color: white;
    padding: 6px 45px;
    font-size: 12px;
  }
  
  .form-group input::placeholder,
  .form-group textarea::placeholder {
    color: #A7A7A7;
    font-size: 12px;
  }

  /* .create-accout-section .img-modal{
    top: 0;
    right: 0;
    position: absolute;
  } */

  .create-accout-section .icon-cross{
    height: 28px;
    
  }
  
  @media (max-width: 768px) {
    .create-accout-section {
      padding: 4vh 3vw;
      border-radius: 0px;
    }
    
    .login-box,
    .signup-box {
      padding: 1vh 1vh; 
    }
    
    .btn-outline-light {
      padding: 6px 30px; 
    }
    .create-accout-section {
     
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      padding: 8vh 2vh;
    
    }
    .welcome-section {
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
  
  @media (max-width: 576px) {

    .create-accout-section {
      padding: 0vh 3vw; 
      border-radius: 0px;
    }

    .login-box,
    .signup-box {
      padding: 1vh 1vh; 
    }
    
    .login-box h2,
    .signup-box h2 {
      font-size: 1.5rem;
    }

    .signup-box p{
      line-height: 2;
    }
    
    .btn-outline-light {
      padding: 6px 20px; 
    }
    .create-accout-section .connect-button {
      margin-top: 0px;
      margin-bottom: 10px;
  }
  .create-accout-section .icon-cross{
    margin-top: 20px;
    margin-right: 12px;
  }
  }
  