.PlainHero-container
{
    width: 100%;
    min-height: 70vh;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 155px;
   
}

.PlainHero-container .container
{
    height: fit-content;
}

.PlainHero-container .heading_capital
{
    font-size: 65px;
    font-weight: 700;
}

.PlainHero-container .h1_main{
    font-weight: 800;
    font-size: 52px;
}

.PlainHero-container .para_main
{
    width: 80%;
    margin: auto;
}

.PlainHero-container .enrolled-text{
    width: fit-content !important;
}

@media(max-width:1201px)
{
    /* .PlainHero-container .container
    {
        flex:0;
    }
    .PlainHero-container .main-container
    {
        height:90%;
    } */

}

@media(max-width:992px)
{
    .PlainHero-container .heading_capital
    {
        font-size: 45px;
        font-weight: 700;
    }
    .PlainHero-container .para_main
    {
        width: 100%;
        margin: auto;
    }
}