.about-container {
  padding: 20px 50px;
}

.about-container .h3_main {
  font-weight: 600;
} 

.about-container .left-section {
  padding: 10vh 0vw; 
}

.right-section {
  padding: 20px 20px;
  text-align: left;
  margin-top: 2rem;
}

.large-img {
  max-width: 100%; 
  height: auto; 
}

.flex-container {
  display: flex;
  align-items: center; 
}

.image-container img {
  margin-bottom: 54px;
}

.image-container .img2{
  margin-top: 18px;
}

.about-container .text-container {
  margin-top: 20px;
  margin-left: 8px;
}

.about-container .text-container > div {
  margin-bottom: 20px; 
  margin-left: 26px;
}

.upper-paragraph {
  margin-top: 28px;
}

.about-container .para_main{
  max-width: 95%;
}

@media (min-width: 576px) {
  .about-container .h1_main {
      font-size: 30px; 
  }

  .h2_main {
      font-size: 22px; 
  }

  .h3_main {
      font-size: 22px; 
  }
}

@media (max-width: 768px) {
  .about-container {
      padding: 20px 40px; 
  }

  .flex-container {
     flex-direction: column;
      text-align: center; 
      margin-bottom: 0px;
  }

  .about-container .text-container > div {
      margin-left: 0px; 
  }
  .about-container .para_main{
    max-width: 100%;
    margin-bottom: 16px;
  }
  .about-container .left-section {
    padding: 8vh 3vw; 
    text-align: center;
  }
  
  .image-container img {
    width: 85%; 
    margin-bottom: 0px;
    margin-top: 30px;
  }
  
  .image-container .img2{
    margin-top: 30px;
    width: 90%;
  }
  .image-container .img1 {
    margin-left: 5px;
    width: 90%;
  }
}

@media (min-width: 992px) {
  .about-container {
      padding: 20px 50px; 
  }

  .about-container .left-section {
      padding: 10vh 5vw; 
  }
}

@media (min-width: 1200px) {
  .about-container .left-section {
      padding: 10vh 0vw; 
  }

  .right-section {
      padding: 20px 40px; 
  }

  .about-container .h1_main {
      font-size: 39px; 
  }

  .about-container .h2_main {
      font-size: 24px; 
  }

  .about-container .h3_main {
      font-size: 24px; 
  }
}
