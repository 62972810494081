.wrapper-div{
   

}

.wrapper-div.bg-1
{
    background-image: url("../../../public/images/creatives/past-paper-text-1.svg");
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 200px;
}
.wrapper-div.bg-2{
    background-image: url("../../../public/images/creatives/past-paper-text-2.svg");
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 250px;
}

.pastpaper-container {
    text-align: center;
    margin: 5% auto;
    max-width: 1200px;
}

.wrapper-div .h1_main {
    font-size: 2.5em;
    margin-bottom: 20px;
}

.wrapper-div .para_main {
    margin-bottom: 40px;
}

.papers {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.paper-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px -6px 40px -4px rgba(0, 0, 0, .1),
        0px 6px 40px -4px rgba(0, 0, 0, .1);
    margin-bottom: 20px;
    padding: 38px;
    text-align: left;
    width: 28%;
    box-sizing: border-box;
}

.card-title {
    font-size: 2.5em;
    font-weight: 700;
}

.card-subtitle {
    font-size: 1.3em;
    color: #1F2F54;
    font-weight: 700;
    margin-top: 8px;
    margin-bottom: 12px;
}

.card-price {
    font-size: 1.5em;
    color: #f00;
    margin-bottom: 20px;
    font-weight: 500;
}

.card-para {
    color: #717C93;
}

.add-to-cart {
    background-color: #007bff;
    border: none;
    border-radius: 7px;
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
}

.add-to-cart:hover {
    opacity: 0.8;
}

.papers .heighted-content
{
    max-height: 185px;
    overflow-y: auto;
}

.papers .heighted-content::-webkit-scrollbar {
    width: 4px; 
}

.papers .heighted-content::-webkit-scrollbar-thumb {
    border-radius: 2px; 
}

.papers .heighted-content::-webkit-scrollbar-track {
    background-color: #f1f1f1; 
}

@media (max-width: 1200px) {
    .paper-card {
        width: 45%;
    }

    .card-title {
        font-size: 2.5em;
    }
}

@media (max-width: 768px) {
    .pastpaper-container {
        margin: 5% auto;
        
    }

    .h1_main {
        font-size: 2em;
    }

    .para_main {
        font-size: 1em;
    }

    .paper-card {
        width: 100%;
        padding: 20px;
        text-align: center;
    }

    .card-title {
        font-size: 2.5em;
    }

    .card-subtitle {
        font-size: 1.2em;
    }

    .card-price {
        font-size: 1.2em;
    }
}

@media (max-width: 480px) {
    .h1_main {
        font-size: 1.5em;
    }

    .para_main {
        font-size: 0.9em;
    }

    .card-title {
        font-size: 2em;
    }

    .card-subtitle {
        font-size: 1em;
    }

    .card-price {
        font-size: 1em;
    }
}