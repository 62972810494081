.important-section {
    padding: 50px 0;
    background-image: url("../../../public/images/creatives/contact-composite-layer.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: auto;
    padding-top: 100px;
    min-height: 1550px;
    
}

.contact-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    background-size: cover;
    background-position: center;
    height: 100vh;
    color: white;
    flex-wrap: wrap;
}

.contact-container input,
.contact-container textarea {
    border: none;
    border-bottom: 1px solid #a7a7a775;
    border-radius: 0%;
    resize: none;
    font-size:large;
    background-color: transparent;

}
.contact-container input:focus{
    outline: none;

    border-bottom: 1px solid #8abeeb75;
}

.contact-container input::placeholder,
.contact-container textarea::placeholder {
    color: #A7A7A7;
    font-size: 16px;
}

.contact-info {
    max-width: 40%;
    flex: 1;
}

.contact-info h1 {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 20px;
    font-family: 'manrope', sans-serif;
}

.contact-info p {
    font-size: 1rem;
    margin-bottom: 20px;
    line-height: 1.8;
    color: #717C93;
    font-family: 'manrope', sans-serif;
}

.contact-details p {
    display: flex;
    align-items: center;
    margin-top: 20px;
    font-family: 'manrope', sans-serif;
}

.social-icons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.contact-form {
    background-color: white;
    color: black;
    padding: 25px 30px;
    border-radius: 21px;
    max-width: 45%;
    flex: 1;
    box-shadow: 0px -6px 40px -4px rgba(0, 0, 0, 0.080),
        0px 6px 40px -4px rgba(0, 0, 0, 0.080);
}

.contact-form .row > [class^="col-"] {
    margin-bottom: 20px;
}
 
.contact-form .h1_main {
    font-size: 2.6rem;
    margin-bottom: 10px;
    font-weight: 700;
}

.contact-form p {
    font-size: 1rem;
    margin-bottom: 20px;
}

.contact-form .form-group {
    margin-bottom: 15px;
}

.contact-form .btn-primary {}

.contact-form .submit-button {
    background-color: #EE202D;
    border: none;
}

@media (max-width: 1200px) {
    .contact-container {
        padding: 30px;
    }

    .contact-info h1 {
        font-size: 2.5rem;
    }

    .contact-form .h1_main {
        font-size: 1.8rem;
    }
}

@media (max-width: 992px) {
    .contact-info {
        max-width: 45%;
    }

    .contact-form {
        max-width: 45%;
    }

    .contact-info h1 {
        font-size: 2.2rem;
    }

    .contact-form .h1_main {
        font-size: 1.6rem;
    }
}

@media (max-width: 768px) {
    .contact-container {
        flex-direction: column;
        height: auto;
        padding: 0px;
    }

    .contact-info {
        max-width: 100%;
        flex: none;
        margin-bottom: 20px;
    }

    .contact-form {
        /* padding: 30px 200px; */
        max-width: 100%;
    }

    .contact-info h1 {
        font-size: 2rem;
    }

    .contact-form .h1_main {
        font-size: 1.5rem;
    }
}

@media (max-width: 576px) {
    .contact-form {
        padding: 32px 12px;
    }

    .contact-info h1 {
        font-size: 1.8rem;
    }

    .contact-form .h1_main {
        font-size: 1.4rem;
    }

    .contact-container {
        padding: 12px 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .contact-details p {
        font-size: 0.9rem;
        
    }

    .contact-form p {
        font-size: 0.9rem;
    }

    .contact-info {}

    .contact-info p {
        font-size: 0.9rem;
    }

    .contact-container .social-icons {
        display: flex;
        justify-content: center;
        margin-bottom: 12px;
    }

    .contact-container .h1_main{
        text-align: center;
    }

    .contact-container .para_main{
        text-align: center;
    }
}