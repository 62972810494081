.thank-you-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 17vh 0vh 8vh 0vh;
    color: white;
    /* White text for contrast */
}

.thank-you-container .content {
    background-color: rgba(255, 255, 255, 0.9);
    /* White background with transparency */
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    /* Subtle shadow for depth */
    max-width: 600px;
    /* Max width for better readability */
    width: 90%;
    /* Responsive width */
    text-align: center;
}

.thank-you-container .h2_main {
    font-size: 2.5rem;
    margin-bottom: 18px;
}

.thank-you-container .thank-you-message {
    font-size: 1.25rem;
    color: #333;
    /* Dark gray text for contrast */
    margin-bottom: 30px;
}

.thank-you-container .h2_sub {
    font-size: 2rem;
    margin-bottom: 10px;
    color: black;
}

.thank-you-container .next-steps {
    list-style-type: none;
    padding: 0;
    margin: 0 0 30px 0;
}

.thank-you-container .next-steps li {
    font-size: 1rem;
    margin-bottom: 10px;
    text-align: left;
    /* Left-align text for easier reading */
    color: black;
}

.thank-you-container .next-steps li strong {
    color: #007bff;
    /* Bootstrap primary color for emphasis */
}

.thank-you-container .connect-button {
    text-decoration: none;
}

.thank-you-container .connect-button:hover {
    background-color: aliceblue;
    color: black;
    border: none;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .thank-you-container .h2_main {
        font-size: 2rem;
        /* Smaller font size for smaller screens */
    }

    .thank-you-container .thank-you-message {
        font-size: 1rem;
        /* Adjusted font size */
    }

    .thank-you-container .h2_sub {
        font-size: 1.5rem;
        /* Smaller subheading font size */
    }

    .thank-you-container .next-steps li {
        font-size: 0.9rem;
        /* Smaller list item font size */
    }

    .thank-you-container .connect-button {
        padding: 10px 20px;
        /* Adjust button padding */
    }
}

@media (max-width: 480px) {
    .thank-you-container {
        padding: 10vh 0vh 5vh 0vh;
        /* Adjusted padding for very small screens */
    }

    .thank-you-container .h2_main {
        font-size: 1.75rem;
        /* Further reduced font size */
    }

    .thank-you-container .thank-you-message {
        font-size: 0.9rem;
        /* Smaller message font size */
    }

    .thank-you-container .h2_sub {
        font-size: 1.25rem;
        /* Smaller subheading font size */
    }

    .thank-you-container .next-steps li {
        font-size: 0.85rem;
        /* Smaller list item font size */
    }
}