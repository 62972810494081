.articles-container {
    padding: 5vh 0;
    position: relative; 
  }
  
  .articles-container .readmore-btn {
    
    color: #EE202D;
  }
  
  .articles-container .custom-card-text {
    color: #1F2F54;
    font-size: 22px;
    font-weight: 700;
  }
  
  .custom-card {
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 38px 30px;
    border-radius: 20px;
  }
  
  .custom-card-body {
    padding: 1.25rem;
  }
  
  .custom-card-title {
    font-size: 1.5rem;
    font-weight: 600;
  }
  
  .custom-card-subtitle {
    font-size: 1rem;
    color: #6c757d;
    margin-bottom: 0.75rem;
  }
  
  .custom-card-link {
    color: #8B8B8B;
    text-decoration: none;
  }
  
  .custom-card-link:hover {
    text-decoration: underline;
  }
  
  .custom-card-img {
    border-radius: 0;
  }
  
  .slider-buttons {
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    width: 45px; 
    height: 45px; 
    background-color: rgba(0, 0, 0, 0.178); 
    color: white; 
    font-size: 24px; 
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    z-index: 1000; 
  }
  
  .slider-buttons.left {
    left: 120px; 
  }
  
  .slider-buttons.right {
    right: 120px; 
  }
  
  @media (max-width: 575.98px) {
    .articles-container {
      text-align: center;
      padding: 5vh 0;
    }
  
    .h1_main {
      font-size: 24px;
    }
  
    .primary-button {
      width: 100%;
      margin-bottom: 28px;
    }
  
    .custom-card-body {
      padding: 1rem;
    }
  
    .custom-card-title {
      font-size: 1.25rem;
    }
  
    .custom-card-subtitle {
      font-size: 0.875rem;
    }
  }
  
  @media (min-width: 576px) and (max-width: 767.98px) {
    .articles-container {
      text-align: center;
    }
  
    .h1_main {
      font-size: 28px;
    }
  
    .primary-button {
      width: 100%;
      margin-bottom: 28px;
    }
  
    .custom-card-body {
      padding: 1.1rem;
    }
  
    .custom-card-title {
      font-size: 1.4rem;
    }
  
    .custom-card-subtitle {
      font-size: 0.95rem;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991.98px) {
    .articles-container {
      text-align: center;
    }
  
    .h1_main {
      font-size: 32px;
    }
  
    .primary-button {
      width: 100%;
      margin-bottom: 28px;
    }
  
    .custom-card-body {
      padding: 1.25rem;
    }
  
    .custom-card-title {
      font-size: 1.5rem;
    }
  
    .custom-card-subtitle {
      font-size: 1rem;
    }
  }
  
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .h1_main {
      font-size: 36px;
    }
  
    .custom-card-body {
      padding: 1.5rem;
    }
  
    .custom-card-title {
      font-size: 1.75rem;
    }
  
    .custom-card-subtitle {
      font-size: 1.1rem;
    }
  }
  
  @media (min-width: 1200px) {
    .articles-container  .h1_main {
      font-size: 40px;
    }
  
    .custom-card-title {
      font-size: 2rem;
    }
  
    .custom-card-subtitle {
      font-size: 1.25rem;
    }
  }
  