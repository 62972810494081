.contact_section {
  padding: 120px 0px 120px 0px;
  width: 100%;
  background-color: #F9F9F9;
  position: relative;
  height: auto;
  z-index: 10;
}

.contact_section .contact_form_form_container input,
.contact_section .contact_form_form_container select
{
  border: none;
  border-radius: 0;
  border-bottom: 1px solid rgb(212, 212, 212);
  color: black;
}

.contact_section h4,
.contact_section h2,
.contact_section p {
  align-self: flex-start;
  text-align: start;
}
.contact_section .contact-details {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: start;
  flex-direction: column;
  margin-bottom: 50px;
}

.contact_section .form-btn {
    font-family: "Inter", sans-serif;
    font-size: 17px;
    padding: 8px 32px;
    letter-spacing: 1px;
    margin: 0;
    border-radius: 4px;
    font-weight: 500;
    border: 1px solid #EE202D!important;
    background-color: #EE202D!important;
    color: white!important;
    transition: 0.1s ease;
    width: 100%;
    box-shadow: 0px 3px 16px 0px #ee202e2b!important;

}
.contact_section .form-btn:hover {
  background-color: #96040e;
}

.contact_section input[type="text"],
.contact_section input[type="tel"],
.contact_section input[type="email"],
.contact_section select,
.contact_section textarea {
  
  padding: 10px 10px;
  font-size: 16px;
  color: rgb(0, 0, 0);
  background: transparent;
  width: 100%;
}

.contact_section select{
  
  color: rgba(57, 57, 57, 0.594)!important;
}

.contact_section input[type="text"]::placeholder,
.contact_section input[type="tel"]::placeholder,
.contact_section input[type="email"]::placeholder,
.contact_section select::placeholder,
.contact_section textarea::placeholder {
  color: rgba(57, 57, 57, 0.594);
  font-size: small;
}

.contact_section input:focus,
.contact_section textarea:focus,
.contact_section select:focus {
  outline: none;
}

.contact_section .button_col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact_section input,
.contact_section textarea,
.contact_section select {
  margin-bottom: 10px;
}
@media (max-width: 992px) {
  .contact_section {
    padding: 40px 0px 40px 0px;
  }
  .contact-details {
    padding-right: 0px;
  }
}


@media(max-width:769px)
{
  .contact_section h4,
.contact_section h2,
.contact_section p {
  align-self: center;
  text-align: center;
}
.contact_section .contact-details {
  margin-bottom: 30px;
}
}