.MyServices-container {
    background-color: #FBFBFB;
    min-height: 50vh;
}

.MyServices-container .service-card-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 26px 20px;
    background-color: #FFFFFF;
    border-radius: 28px;
    box-shadow: 0px -6px 40px -4px rgba(0, 0, 0, 0.080),
    0px 6px 40px -4px rgba(0, 0, 0, 0.080);
}

.MyServices-container .text-content {
    margin-top: 19px;

}

.MyServices-container .text-content .para_main {
    font-size: 16px;
    word-wrap: break-word;
    max-width: 600px;
}

.MyServices-container .body-paragraph {
    line-height: 2;
}

.MyServices-container .service-card-container .card-title {
    font-size: 23px;
    font-weight: bold;
    color: #1F2F54;
}

.MyServices-container .service-card-container .card-content {
    font-size: 16px;
    flex: 1;
    max-width: 380px;
    word-wrap: break-word;
}

.MyServices-container .service-card-container .card-anchor {
    font-size: 16px;
    cursor: pointer;
    transition: .3s;
}

.MyServices-container .service-card-container .card-anchor:hover {
    color: #023c58;
}

.MyServices-container .card-sized-box {
    overflow-y: scroll;
    height: 484px;
    padding: 3vh 0vh;
}

.MyServices-container .card-sized-box::-webkit-scrollbar {
    width: 5px; 
    background-color: #f1f1f1; 
    
}

.MyServices-container .card-sized-box::-webkit-scrollbar-thumb {
    background-color: #00000069; 
    border-radius: 4px; 
}

.MyServices-container .card-sized-box::-webkit-scrollbar-track {
    background-color: #f1f1f1; 
}

.MyServices-container .values-image {
    width: 100px;
    padding-bottom: 65px;
}
.MyServices-container .h1_main{
    margin-bottom: 14px;
}

@media (max-width: 768px) {
    .MyServices-container {
        padding: 10px;
        
        
    }
    .MyServices-container .service-card-container{
        text-align: center;
       
       
    }

    .MyServices-container .text-content {
        margin-top: 10px;
    }

    .MyServices-container .service-card-container .card-title {
        font-size: 18px;
    }

    .MyServices-container .service-card-container .card-content {
        font-size: 12px;
    }

    .MyServices-container .service-card-container .card-anchor {
        font-size: 12px;
    }

    .MyServices-container .card-sized-box {
        max-height: 380px;
    }

    .MyServices-container .card-btn {
        padding: 10px;
    }

    .MyServices-container .card-btn-img {
        width: 25px;
    }
    .MyServices-container .values-image{
        
    }
    .MyServices-container .card-sized-box::-webkit-scrollbar{
        display: contents;
    }
}

@media (max-width: 576px) {
    .MyServices-container {
        padding: 5px;
    }

    .MyServices-container .text-content {
        margin-top: 5px;
    }

    .MyServices-container .service-card-container .card-title {
        font-size: 16px;
    }

    .MyServices-container .service-card-container .card-content {
        font-size: 10px;
    }

    .MyServices-container .service-card-container .card-anchor {
        font-size: 10px;
    }

    .MyServices-container .card-sized-box {
        max-height: 300px;
    }

    .MyServices-container .card-btn {
        padding: 8px;
    }

    .MyServices-container .card-btn-img {
        width: 20px;
    }
}

@media (max-width: 1024px) {
    .MyServices-container {
        padding: 15px;
    }

    .MyServices-container .text-content {
        margin-top: 35px;
    }

    .MyServices-container .service-card-container .card-title {
        font-size: 20px;
    }

    .MyServices-container .service-card-container .card-content {
        font-size: 14px;
    }

    .MyServices-container .service-card-container .card-anchor {
        font-size: 14px;
    }

    .MyServices-container .card-sized-box {
        max-height: 400px;
    }

    .MyServices-container .card-btn {
        padding: 12px;
    }

    .MyServices-container .card-btn-img {
        width: 30px;
    }
}