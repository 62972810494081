.SideSlider-container {
    min-height: 100vh;
    height: 100%;
    border-right: 2px solid #F2F2F2;
    padding-top: 35px;
    padding-right: 10px;
}

.SideSlider-container .tab-title {
    font-weight: bold;
    color: black;
    text-transform: capitalize;
}

.SideSlider-container .active-tab-title {
    color: #EE202D !important;
}

.SideSlider-container .card {
    background-color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.173);
    border: none;
    padding: 20px 20px;
    border-radius: 10px;
    width: 100%;
    cursor: pointer;
}

@media(max-width:992px) {
    .SideSlider-container {
        min-height: auto;
        height: auto;
        position: absolute;
        background-color: white;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.173);
        border: none;
        border-radius: 12px;
        padding: 4vh 1vh;
        z-index: 100;  
        animation: fade 0.2s ease-in-out forwards;
    }
    .SideSlider-container .card {
        background-color: white;
        box-shadow: none;
        padding: 1vh 1vh;
        border-radius: 0px;
       
        border: none;
        
        
    }
    

    
}

@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}