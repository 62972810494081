.backgroundVideo {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1; 
}


.Hero {
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  max-height:auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
  /* padding-bottom: 30px; */
  
    
}

.redtext
{
  color: #EE202D!important;
}

.homeherosmallheading
{
  font-size: 25px;
  text-transform: uppercase;
}

.homeheromainheading
{
  font-size: 65px;
  font-weight: 700;
}

.homeheropara
{
  width: 60%!important;
}


  .heading_main {
    font-family: "Mulish", sans-serif;
    font-size: 32px;
    color: #1d1d1d;
    font-weight: 800;
    text-transform: uppercase;
    margin: 0;
  }
  .backgroundImage {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  
  .Hero_Content {
    max-width: 1320px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 25px;
    flex-grow: 1;
    margin-top: 70px;
    padding-top: 135px;
    

  }
  .Hero_Content h1 {
    font-weight: 900;
    color: white;
    letter-spacing: 3px;
    text-align: center;
    align-self: center;
    font-size: 88px;
  }
  .Hero_Content h3 {
    text-align: center;
    align-self: center;
    color: white;
    letter-spacing: 4px;
  }

  .Hero_Content p{
    color: white;
    text-align: center;
  }

 

  

  



  @media (max-width: 480px) {
    .Hero_Content h1 {
      font-size: 30px; /* Decrease font size for smaller screens */
      padding: 0 10px; /* Adjust padding for smaller screens */
    }
  
    .Hero_Content h3 {
      font-size: 14px; /* Decrease font size for smaller screens */
      padding: 0 10px; /* Adjust padding for smaller screens */
    }
  
    
  }
  
  @media (max-width: 1200px) {
    .Hero_Content h1 {
      font-size: 55px;
    }
    .Hero_Content {
      
      padding-top: 0px;
    }
  }
  @media (max-width: 767px) {
    

    .homeherosmallheading
    {
      font-size: 22px;
      text-transform: uppercase;
    }

    .homeheromainheading
    {
      font-size: 45px;
      font-weight: 700;
    }

    .homeheropara
    {
      width: 100%;
    }
    
    .Hero_Content h1 {
      padding: 0 3% 0 3%;
    }
    .Hero_Content h3 {
      padding: 0 20% 0 20%;
    }
    .Hero_Content h1 {
      font-size: 35px;
    }
    .Hero_Content h3 {
      font-size: 16px;
    }
  }
  @media (min-height: 1100px) {
    .video {
      /* height: 65%; */
    }
  }