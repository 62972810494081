
.main-cont{
    padding: 1vh 1vh;
}

.main-cont .h1_main{
    font-size: 22px;
    margin-left: 12px;
}
.EnrolledPastPaperCard-container
{
    background-color: transparent;
    background-size: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;

}



.EnrolledPastPaperCard-container .btn-container
{
    /* width: 50%; */
}

.EnrolledPastPaperCard-container .main-heading
{
    font-size: 42px;
    font-weight: 500;
    color: #1e1e1e;
}
.EnrolledPastPaperCard-container .card-box
{
    color: black;
    padding:  10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.097);
    border-radius: 15px;
    cursor: pointer;
    height: 100%;
    background-color: white;
}

.EnrolledPastPaperCard-container .swiper-slide
{

    height: 100%;
}

.EnrolledPastPaperCard-container .card-box .card-text-content
{
    width: 93%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: auto;
}

.EnrolledPastPaperCard-container .card-box h5{
    color :#373737;
    font-size: .9rem;
    font-weight: 700;
    margin-top: 10px;
}
.EnrolledPastPaperCard-container .card-box p{
    color :#747474;
    font-size: .78rem;
}

.EnrolledPastPaperCard-container .card-box .read-more-anchor
{
    font-family: "Inter", sans-serif;
    font-size: 10px;
    font-weight: 400;
    padding: 8px 14px;
    letter-spacing: 1px;
    margin: 0;
    border-radius: 4px;
    border: 1px solid #EE202D!important;
    background-color: #EE202D!important;
    color: white!important;
    transition: 0.1s ease;
    
    box-shadow: 0px 3px 16px 0px #ee202e2b!important;
    
}

.EnrolledPastPaperCard-container .card-box img{
    width: 95%;
    margin:5% auto;
    margin-bottom: 10px;
    border-radius: 8px;
}

.EnrolledPastPaperCard-container.card-box .top-links
{
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.EnrolledPastPaperCard-container .card-box .blue-box
{
    background-color: #D8F3F4;
    padding: 3px 8px;
    border-radius: 5px;
    
}
.EnrolledPastPaperCard-container .card-box .blue-box p
{
    color: #71b0f9;
    
}
.EnrolledPastPaperCard-container .card-box .red-text
{
    color: #EE202D;
    font-weight: 500
    
}
.EnrolledPastPaperCard-container .card-box .title
{
    font-size: 1rem;
    
}

.EnrolledPastPaperCard-container .card-box .top-links img{
    width: 15px;
}
.EnrolledPastPaperCard-container .card-box .top-links p{
    font-size: .8rem;

}

.EnrolledPastPaperCard-container .blog-text-content
{
    margin-bottom: 40px;
}

.EnrolledPastPaperCard-container .blog-upper-content
{
}

.EnrolledPastPaperCard-container .blog-upper-content .blog-text-content
{
    
}
.EnrolledPastPaperCard-container .blog-upper-content .blog-btn
{
    display: flex;
    align-items: center;
    justify-content: center;
}
.EnrolledPastPaperCard-container .blog-upper-content .blog-btn button
{
    padding: 7px 30px;
}

.EnrolledPastPaperCard-container .card-list
{
    margin: auto;
    width: 90%;
}

.EnrolledPastPaperCard-container .blue-btn 
{
    margin-top: 40px;
}

.EnrolledPastPaperCard-container .gen-btn
{
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    padding: 15px 45px;
    letter-spacing: 1px;
    margin: 0;
    border-radius: 4px;
    font-weight: 500;
    text-align: center;
    
    
  
  }
  .EnrolledPastPaperCard-container .gen-btn-active {
    border: 1px solid #EE202D!important;
    background-color: #EE202D!important;
    color: white!important;
    transition: 0.1s ease;
    padding: 15px 45px;
    border-radius: 6px;
   
    
    box-shadow: 0px 3px 16px 0px #ee202e2b!important;

  }
  .EnrolledPastPaperCard-container .gen-btn {
    border: 1px solid #FFFFFF;
    background-color: #FFFFFF;
    color: black;
    transition: 0.1s ease;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.097);
  }

  .EnrolledPastPaperCard-container .head-h1{
    font-size: 20px;
  }


@media(max-width:768px)
{
    .EnrolledPastPaperCard-container .blog-upper-content .blog-btn
    {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .CourseCard-container .gen-btn-active{
       
    
    }
    .EnrolledPastPaperCard-container .gen-btn{
        margin-bottom: 16px;
        font-size: 10px;
        
       
    }
    .EnrolledPastPaperCard-container .card-box .read-more-anchor{
        margin-left: 12px;
    }
    .main-cont .h1_main{
        font-size: 22px;
        text-align: center;
    }
}