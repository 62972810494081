.footer {
  background-image: url('/public/images/Group-1910.jpg');
  background-size: cover; /* or contain, or specific dimensions */
  background-position: center; /* center, or specific coordinates */
  background-repeat: no-repeat; /* no-repeat, repeat-x, repeat-y, or repeat */
  color: #fff;
  padding: 120px 0 40px;
  text-align: left;
  width: auto;
}

.footer .upper-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer .span-hover:hover {
  color: #EE202D;
  cursor: pointer;
}
/* HB-care code starting from here */


.footer .footer-second-section{
  flex: 1;
  margin: 0 0px;
}

.footer .footer-second-section{
  display: block;
  /* grid-template-columns: repeat(2, 1fr); */
  gap: 10px;
  list-style: none;
  padding: 0px 70px;
  line-height: 2.5;
  
  font-size: 14px;
  
}

.footer .footer-second-section ul li a {
  color: white;
  text-decoration: none;
  
}

.footer .footer-second-section ul li a:hover {
  color: #EE202D;
}

.footer-sect {
  flex: 1;
  margin: 0 46px;
}

.footer .head-h1{
  font-size: 16px;
  color: #e72330;
}

.footer-sect ul {
  display: column;
  /* grid-template-columns: repeat(2, 1fr); */
  gap: 10px;
  list-style: none;
  padding: 0px 45px;
  line-height: 2.5;
  
  font-size: 14px;
}

.footer-sect ul li a {
  color: white;
  text-decoration: none;
}

.footer-sect ul li a:hover {
  color: #EE202D;
}


/* end here */

ul {
  list-style: none;
  padding: 0;
  /* margin-left: 4.9rem; */
  line-height: 2.5;
}

 /* ul li a {
  color: white;
  text-decoration: none;
} */

/* ul li a:hover {
  color: #EE202D;
}  */




/* .footer-sect {
  flex: 1;
  margin: 0px;
}

.footer-sect ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  list-style: none;
  padding: 0;
  line-height: 2.5;
}

.footer-sect ul li a {
  color: white;
  text-decoration: none;
}

.footer-sect ul li a:hover {
  color: rgb(223, 28, 28);
} */

.footer-section .logo {
  /* margin-left: 14rem; */
}

.footer-section .footer-text {
  flex: 2;
  max-width: 397px;
  text-align: left;
  word-wrap: break-word;
  /* margin-left: 14rem; */
  padding-top: 1.8rem;
  line-height: 2;
  color: #EAEBEB;
  font-size: 16px;
}

.footer-section {
}

.specific-paragraph {
  display: flex;
  align-items: center;
  font-size: 16.5px;
  text-align: left;
  /* margin: 10px 0; */
  line-height: 1.5;
  margin-bottom: 14px;
  /* margin-right: 14rem; */
  color: #F0F0F0;
}

.contact-icon {
  margin-right: 10px;
}

.footer .footer-heading {
  margin-top: 20px;
  margin-left: 7rem;
  font-size: 35px;
}



.footer .footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  position: relative;
  padding: 10px;
}

.footer .blog-subfooter {
  /* margin: 0 122px; */
}

.footer .p-text {
  font-size: 14px;
  margin-top: 18px;
  /* margin-left: 5rem; */
  color: #F0F2F5;
}

.footer .social-icons {
  display: flex;
  margin-top: 20px;
  justify-content: flex-start;
  /* padding-left: 13.5rem; */
  padding-bottom: 80px;
}

.footer .social-icons-bottom {
  display: flex;
  gap: 10px;
  margin-top: 8px;
  /* padding-right: 68px; */
}

.footer .icons {
  width: 38px;
  height: 38px;
}

.footer .icon {
  color: white;
  margin-left: 10px;
  font-size: 35px;
  cursor: pointer;
}

.footer .logo-left {
  padding: 0 8px;
}

.footer .icon:hover {
  color: red;
}

.footer-section-right .contact-icon {
  padding-right: 10px;
}

.divider {
  width: 100%;
  margin: 0 auto;
}


@media (max-width: 1200px) {
  .footer-section .logo,
  .footer-section .footer-text,
  .specific-paragraph,
  .footer .footer-heading,
  ul,
  .footer .social-icons,
  .footer .blog-subfooter {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .footer-sect {
    margin: 0 28px;
  }

  .footer .social-icons {
    display: flex;
    margin-top: 20px;
    justify-content: flex-start;
    padding-left: 0rem;
    padding-bottom: 80px;
  }
}

@media (max-width: 992px) {
  .footer {
    padding: 120px 0 30px;
    text-align: center; /* Centers inline elements like text */
  }

  .footer .upper-section {
    flex-direction: column;
    align-items: center; /* Centers flex items horizontally */
  }

  .footer-section .logo,
  .footer-section .footer-text,
  .specific-paragraph,
  .footer .footer-heading,
  ul,
  .footer .social-icons,
  .footer .blog-subfooter {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .footer .specific-paragraph{
    flex-direction: column;
  }

  .footer-sect {
    margin: 0;
   
    flex-direction: column;
  }

  .footer .footer-sect ul {
    display: block;
  }

  ul {
    
    flex-direction: column;
    align-items: center; /* Centers the list items horizontally */
  }

  .footer .social-icons {
    display: flex;
    justify-content: center; /* Centers the social icons horizontally */
  }
  .footer .p-text{
    margin-left: 0px;
  }
  .footer .footer-content{
    display: block;
  }
  .footer .divider{
    margin-top: 12px;
  }
  .footer .social-icons-bottom {
    padding-right: 0;
    justify-content: center;
  }
  .footer-section-right .contact-icon {
    padding-right: 0px;
    margin-bottom: 8px;
    margin-top: 8px;
    margin-right: 0px;
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 100px 0 20px;
  }

  .footer .upper-section {
    text-align: center;
    flex-direction: column;
  }

  .footer-section .logo,
  .footer-section .footer-text,
  .specific-paragraph,
  .footer .footer-heading,
  ul,
  .footer .social-icons,
  .footer .blog-subfooter {
    margin: 0 auto;
    text-align: center;
  }

  .footer .specific-paragraph{
    flex-direction: column;
  }

  .footer-sect {
    margin: 0;
    width: 100%;
    
  }

  .footer-sect ul {
    grid-template-columns: 1fr;
  }

  .footer .social-icons {
    justify-content: center;
    padding-left: 0;
    margin-top: 14px;
  }

  .footer .social-icons-bottom {
    padding-right: 0;
    justify-content: center;
   
  }
  .footer-section-right .contact-icon {
    padding-right: 0px;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .footer .p-text{
    margin-left: 0px;
  }
  .footer .footer-content{
    display: block;
  }
  .footer .divider{
    margin-top: 12px;
  }
  .footer .footer-sect ul {
    display: block;
    grid-template-columns: none;
    gap: 10px;
    list-style: none;
    padding: 0;
    margin-top: 18px;
    line-height: 2.5;
  }
}

@media (max-width: 576px) {
  .footer {
    padding: 80px 0 10px;
  }

  .footer .footer-content {
    display: block;
    text-align: center;
  }
  .footer-section-right .contact-icon{
    margin-bottom: 7px;
    
  }

  .footer .footer-content .text-content {
    margin-bottom: 20px;
  }

  .footer-section .logo,
  .footer-section .footer-text,
  .specific-paragraph,
  .footer .footer-heading,
  ul,
  .footer .social-icons,
  .footer .blog-subfooter {
    margin: 0 auto;
    text-align: center;
    padding: 10px 10px;
  }

  .footer .footer-heading {
    font-size: 28px;
    margin-top: 10px;
  }

  .specific-paragraph {
    font-size: 18px;
    flex-direction: column;
    align-items: center;
  }

  .footer .p-text {
    font-size: 14px;
    text-align: center;
    margin-left: 0;
  }

  .footer .icons {
    width: 25px;
    height: 25px;
    justify-content: center;
  }

  .footer .icon {
    font-size: 28px;
  }
}
