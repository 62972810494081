.Testimonials-container
{
    background-color: white;
    display: flex;
    align-items: center;
    width: 80%;
    margin: auto;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.097);
    justify-content: center;
    border-radius: 10px;
    position: relative;
    top: 90px;
    z-index: 50;
}

.Testimonials-container .testimonial-card
{
    cursor: pointer;
    width: 100%;

}
.Testimonials-container .testimonial-card .arrow-img
{
    width:45px;
}

.Testimonials-container .testimonial-card .red-text
{
    color: #EE202D;
    font-weight: 500;
    
}


.Testimonials-container .testimonial-card .star-img
{
    width: 110px;
}


.Testimonials-container .testimonial-card .testimonial-card-info
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}


.Testimonials-container .profile-info
{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.Testimonials-container .profile-info img{
    border-radius: 50%;
    width: 60px;
}

.Testimonials-container .profile-info .review-text
{
    margin-top: 20px;
    margin-bottom: 30px;
    width: 80%;
}

.Testimonials-container .main-heading
{
    font-size: 42px;
    font-weight: 500;
    color: #1e1e1e;
}

.Testimonials-container .profile-info .name
{
    font-size: 1.4rem;
    margin-top: 10px;
    font-weight: 600;
}

.Testimonials-container .swiper-pagination
{
   /* bottom: -15px!important; */
   position: relative;
}
.Testimonials-container .swiper-pagination .swiper-pagination-bullet
{
    background-color: #dedede;
    opacity: 1;
    width: 11px;
    height:11px;
    
}
.Testimonials-container .swiper-pagination .swiper-pagination-bullet-active
{
    background-color: #CB1212;
    opacity: 1;
    width: 13px;
    height:13px;
}
@media(max-width:800px)
{
    .Testimonials-container
    {
        width: 90%;
        transform: translateY(-115px);
    }
    .Testimonials-container .profile-info .review-text
    {
        width: 100%;
    }
}