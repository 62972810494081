.testmark-container {
  padding: 2vh 0vh;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.testmark-container .row {
  margin: 0;
}

.testmark-container .img-modal {
  top: 0;
  right: 0;
  position: absolute;
}

.testmark-container .icon-cross {
  width: 30px;
  cursor: pointer;
}

.test-marks-popup-box {
  padding: 5vh 3vh;
}

.testmark-container .h1_main {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 60px;
}

.testmark-container .form-group {
  margin-bottom: 15px;
}

.testmark-container .placeholder-form-control {
  width: 100%;
  padding: 10px;
  font-size: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: rgb(204, 204, 204);
}

.testmark-container .form-control {
  border: none;
  border-bottom: 1px solid rgb(212, 212, 212);
  padding-bottom: 5px;
  margin-bottom: 20px;
}

.testmark-container .select-wrapper {
  position: relative; /* Ensure relative positioning for absolute positioning of arrow */
}

/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
  .testmark-container {
    padding: 2vh 1vh;
  }

  .testmark-container .h1_main {
    font-size: 20px;
    margin-bottom: 40px;
  }
}

@media (max-width: 992px) {
  .testmark-container {
    padding: 2vh 0.5vh;
  }

  .testmark-container .form-control {
    margin-bottom: 15px;
  }
}

@media (max-width: 768px) {
  .testmark-container {
    padding: 1.5vh 0.5vh;
  }

  .testmark-container .h1_main {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .testmark-container .form-control {
    margin-bottom: 10px;
  }
}

@media (max-width: 576px) {
  .testmark-container {
    padding: 9vh 0.5vh;
    border-radius: 12px;
  }

  .testmark-container .h1_main {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .testmark-container .form-control {
    margin-bottom: 5px;
  }
  .testmark-container .icon-cross {
    height: 28px;
    margin-top: 13px;
    margin-right: 6PX;
  }
}
