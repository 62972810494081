.CourseHero-container {
    background-color: transparent;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
}

.CourseHero-container .h1_main {
    font-size: 65px;
    font-weight: 700;
}

.CourseHero-container .btn-container {
    width: 50%;
    margin-top: 20px;
}

.CourseHero-container .main-heading {
    font-size: 42px;
    font-weight: 500;
    color: #1e1e1e;
}


.CourseHero-container .gen-btn {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    padding: 15px 45px;
    letter-spacing: 1px;
    margin: 0;
    border-radius: 4px;
    text-align: center;
    /* white-space: nowrap; */
    height: 100%;
    display: inline-block;
}

.CourseHero-container .gen-btn-active {
    border: 1px solid #EE202D !important;
    background-color: #EE202D !important;
    color: white !important;
    transition: 0.1s ease;
    padding: 15px 45px;
    border-radius: 6px;
    font-size: 14px;
    height: 100%;
    box-shadow: 0px 3px 16px 0px #ee202e2b !important;

}

.CourseHero-container .gen-btn {
    border: 1px solid #FFFFFF;
    background-color: #FFFFFF;
    color: black;
    transition: 0.1s ease;
    font-size: 14px;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.097);
}

.CourseHero-container .blog-text-content {
    margin-bottom: 40px;
}

.CourseHero-container .blog-upper-content {
    margin-top: 130px;
}

.CourseHero-container .blog-upper-content .blog-btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.CourseHero-container .blog-upper-content .blog-btn button {
    padding: 7px 30px;
}

@media(max-width:768px) {
    /* .CourseHero-container {
        height: 55vh;
    } */

    .CourseHero-container .blog-upper-content .blog-btn {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .CourseHero-container .blog-upper-content {
        margin-top: 30px;
    }

    .CourseHero-container .gen-btn {
        margin-bottom: 16px;
        font-size: 11px;
    }

    .CourseHero-container .gen-btn-active {
        font-size: 11px;
    }

    .CourseHero-container .h1_main {
        font-size: 60px;
        font-weight: 700;
    }
}

@media(max-width:556px) {
    /* .CourseHero-container {
        height: 60vh;
    } */

    .CourseHero-container .blog-upper-content .blog-btn {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .CourseHero-container .blog-upper-content {
        margin-top: 30px;
    }

    .CourseHero-container .gen-btn {
        margin-bottom: 16px;
        font-size: 10px;
    }

    .CourseHero-container .gen-btn-active {
        font-size: 10px;
    }

    .CourseHero-container .h1_main {
        font-size: 60px;
        font-weight: 700;
    }

    .CourseHero-container .btn-container {
        width: 50%;
        margin-top: 20px;
    }

}

@media(max-width:385px) {
    /* .CourseHero-container {
        height: 74vh;
    } */

    .CourseHero-container .blog-upper-content .blog-btn {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .CourseHero-container .blog-upper-content {
        margin-top: 30px;
    }

    .CourseHero-container .gen-btn {
        margin-bottom: 16px;
        font-size: 9px;

    }

    .CourseHero-container .gen-btn-active {
        font-size: 9px;
    }

    .CourseHero-container .h1_main {
        font-size: 60px;
        font-weight: 700;
    }

    .CourseHero-container .btn-container {
        width: 50%;
        margin-top: 20px;
    }

}

@media only screen and (min-width: 769px) and (max-width: 992px) {
    .CourseHero-container .gen-btn {
        margin-bottom: 16px;
        font-size: 11px;
    }

    .CourseHero-container .gen-btn-active {
        font-size: 11px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    /* .CourseHero-container {
        height: 100vh;
    } */

    .CourseHero-container .gen-btn {
        margin-bottom: 16px;
        font-size: 11px;
    }

    .CourseHero-container .gen-btn-active {
        font-size: 11px;
    }
}